import "../common/styles/main.scss";

import { PreloaderOverlay } from "@rototip/lib-ui/common/PreloaderOverlay";
import AES from "crypto-js/aes";
import type { AppProps } from "next/app";

import { patchUserDetails, setCookies, storeWrapper } from "@rototip/lib-redux";
import { DevTools, FormatSimple, Tolgee } from "@tolgee/web";
import { IncomingMessage } from "http";
import { getToken, type JWT } from "next-auth/jwt";
import { useRouter } from "next/router";

import { Suspense, lazy, useEffect, useState, useTransition } from "react";

import { useTolgeeSSR } from "@tolgee/react";
import enLocale from "../i18n/en.json";
import nlLocale from "../i18n/nl.json";
import trLocale from "../i18n/tr.json";

import Clarity from "../features/analytics/clarity";
import GoogleAnalytics from "../features/analytics/googleAnalytics";
import HubspotAnalytics from "../features/analytics/hubspotAnalytics";

const tolgee = Tolgee()
	.use(DevTools())
	.use(FormatSimple())
	.init({
		defaultLanguage: "en",
		staticData: {
			en: enLocale,
			tr: trLocale,
			nl: nlLocale,
		},

		// for development mode
		apiUrl:
			process.env.NEXT_PUBLIC_CLOUD_ENV === "local"
				? process.env.NEXT_PUBLIC_TOLGEE_API_URL
				: undefined,
		apiKey:
			process.env.NEXT_PUBLIC_CLOUD_ENV === "local"
				? process.env.NEXT_PUBLIC_TOLGEE_API_KEY
				: undefined,
	});

const App = lazy(() => import("../appWrapper"));
const TolgeeProvider = lazy(() =>
	import("@tolgee/react").then(({ TolgeeProvider }) => ({
		default: TolgeeProvider,
	}))
);
const ReduxProvider = lazy(() =>
	import("react-redux").then(({ Provider }) => ({
		default: Provider,
	}))
);
const SessionProvider = lazy(() =>
	import("next-auth/react").then(({ SessionProvider }) => ({
		default: SessionProvider,
	}))
);
const ChakraProvider = lazy(() =>
	import("@chakra-ui/react").then(({ ChakraProvider }) => ({
		default: ChakraProvider,
	}))
);

export type RototipAppProps = AppProps<{
	appVersion: string;
	cloudEnv: string;
	staticData: { [locale: string]: typeof enLocale };
}>;

function RototipApp({ Component, ...appProps }: RototipAppProps) {
	const [, startTransition] = useTransition();

	const [theme, setTheme] = useState<Record<string, any> | undefined>();
	const router = useRouter();
	const activeLocale = router.locale;
	const { store, props } = storeWrapper.useWrappedStore(appProps);
	const { pageProps } = props;

	useEffect(() => {
		(async () => {
			const result = await import("@rototip/lib-ui/theme");
			startTransition(() => {
				setTheme(result.theme);
			});
		})();
	}, []);

	const ssrTolgee = useTolgeeSSR(tolgee, activeLocale, pageProps.staticData);

	return (
		theme !== undefined && (
			<Suspense fallback={<PreloaderOverlay />}>
				<ChakraProvider theme={theme}>
					<ReduxProvider store={store}>
						<SessionProvider session={props.session}>
							<TolgeeProvider tolgee={ssrTolgee}>
								<App
									Component={Component}
									pageProps={pageProps}
									cloudEnv={pageProps.cloudEnv}
									appVersion={pageProps.appVersion}
								/>
								<GoogleAnalytics />
								<Clarity />
								<HubspotAnalytics />
							</TolgeeProvider>
						</SessionProvider>
					</ReduxProvider>
				</ChakraProvider>
			</Suspense>
		)
	);
}

RototipApp.getInitialProps = storeWrapper.getInitialAppProps(
	(store) =>
		async (context): Promise<any> => {
			let locale: string | undefined;
			if (context.router) {
				locale = context.router.locale;
			}
			const req = context.ctx.req as IncomingMessage & {
				cookies: Partial<{
					[key: string]: string;
				}>;
			};

			const token = (await getToken({
				req,
			})) as (JWT & { id: string }) | null;
			if (token) {
				// @ts-expect-error user shape mismatch
				store.dispatch(patchUserDetails(token!));

				// get cookies
				const cookies = req.cookies;
				const forwardCookiesToClient: Record<string, string> = {};
				Object.keys(cookies).forEach((key: string) => {
					if (/next-auth/.test(key)) {
						forwardCookiesToClient[key] = cookies[key]!;
					}
				});
				store.dispatch(
					setCookies(
						AES.encrypt(
							JSON.stringify(forwardCookiesToClient),
							token?.id!
						).toString()
					)
				);
			}

			return {
				pageProps: {
					cloudEnv: process.env.CLOUD_ENV,
					appVersion: process.env.APP_VERSION,
					staticData: {
						[locale!]: await import(`../i18n/${locale}.json`),
					},
				},
			};
		}
);

export default RototipApp;
