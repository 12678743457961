import {
	isRejected,
	isRejectedWithValue,
	type Middleware,
	type MiddlewareAPI,
} from "@reduxjs/toolkit";
import * as Sentry from "@sentry/nextjs";

export const errorMiddleware: Middleware =
	(api: MiddlewareAPI) => (next) => (action) => {
		try {
			if (isRejectedWithValue(action) || isRejected(action)) {
				if (action?.error?.name === "ConditionError") {
					// we can ignore condition errors raised during hydration
					// that just means query is rejected because we already have the data
					return next(action);
				}
				console.error("[Rejected] RTK middleware caught error:", action.error);
				Sentry.captureException(action.error);
			}
			return next(action);
		} catch (error) {
			console.error("[Uncaught] RTK middleware caught error:", error);
			Sentry.captureException(error);
		}
	};
