export type SpinnerProps = {
	size?: number;
};

export const LoadingSpinner = (props: SpinnerProps = { size: 64 }) => (
	<div>
		<style>{`
      @keyframes loading-spinner {
        from {
          transform: rotate3d(0, 1, 0, 0deg);
        }
      
        to {
          transform: rotate3d(0, 1, 0, 360deg);
        }
      }      
    `}</style>
		<svg
			style={{
				animation: "loading-spinner 1s linear infinite",
				fill: "rgb(50, 115, 140)",
			}}
			viewBox="13.742 9.592 126.003 280.996"
			width={props.size}
			height={props.size}
		>
			<g
				id="Layer_2"
				data-name="Layer 2"
				transform="matrix(1, 0, 0, 1, 13.742162, 9.587864)"
			>
				<g id="Layer_1-2" data-name="Layer 1">
					<path d="M110.17,143.76h-50V15.38c0-9.2,6.81-14.27,13.56-15.21S88.44,2.22,91.12,11l34.23,113a14.92,14.92,0,0,1-2.51,13.55A15.88,15.88,0,0,1,110.17,143.76Zm-46-3.88h46.05a11.84,11.84,0,0,0,9.47-4.61,11.16,11.16,0,0,0,1.88-10.13l-34.23-113c-2-6.6-8-8.84-13-8.13S64.12,8.5,64.12,15.38Z"></path>
					<path d="M48.15,281c-6.18,0-12.74-3.28-15.06-11.15L.62,159.48A14.9,14.9,0,0,1,3.2,146a15.87,15.87,0,0,1,12.63-6.11H64.12V265.62c0,9.23-6.85,14.3-13.63,15.22A16.52,16.52,0,0,1,48.15,281ZM15.83,143.76a11.82,11.82,0,0,0-9.44,4.57,11.13,11.13,0,0,0-1.93,10.08L36.93,268.78c2,6.64,7.94,8.91,13,8.22s10.19-4.48,10.19-11.38V143.76Z"></path>
				</g>
			</g>
		</svg>
	</div>
);
