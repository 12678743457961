import { LoadingSpinner } from "../LoadingSpinner/LoadingSpinner";

export type PreloaderOverlayProps = {
	fit?: "screen" | "container";
};

export const PreloaderOverlay = ({ fit = "screen" }: PreloaderOverlayProps) => (
	// we want the styles to be available immediately,
	// so we're placing them directly
	<div
		style={{
			width: `100${fit === "screen" ? "vw" : "%"}`,
			height: `100${fit === "screen" ? "vh" : "%"}`,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		}}
	>
		<LoadingSpinner size={64} />
	</div>
);

export default PreloaderOverlay;
