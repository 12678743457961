import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { orderApi } from "../orderApi";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	CreateOrderMessage,
	OrderMessage,
} from "@rototip/lib-order/entities/order-message";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

export const orderMessageApi = createApi({
	reducerPath: "api:order-message",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order/messages`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		createOrderMessage: builder.mutation<OrderMessage, CreateOrderMessage>({
			query: (body) => ({
				url: `/`,
				method: "POST",
				body,
			}),
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				try {
					void (await queryFulfilled);
					dispatch(orderApi.util.invalidateTags(["order"]));
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useCreateOrderMessageMutation } = orderMessageApi;

// export endpoints for use in SSR and with form manager
export const { createOrderMessage } = orderMessageApi.endpoints;
