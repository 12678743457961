import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import dayjs from "dayjs";
import { HYDRATE } from "next-redux-wrapper";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	PartEntity,
	PresignRequestDto,
	UpdatePartDto,
} from "@rototip/lib-order";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

/**
 * A proxy to svc-order part endpoint calls
 * @see: packages/svc-order/src/parts/parts.controller.ts
 */
export const partApi = createApi({
	reducerPath: "api:parts",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order`,
		prepareHeaders,
	}),
	tagTypes: ["Part"],
	extractRehydrationInfo(action, { reducerPath }) {
		if (action.type === HYDRATE) {
			return action.payload[reducerPath];
		}
	},
	endpoints: (builder) => ({
		getPartsByOrderId: builder.query<PartEntity[], string>({
			query: (orderId) => `/parts/order/${orderId}`,
			providesTags: ["Part"],
		}),
		updatePart: builder.mutation<
			PartEntity,
			[string, UpdatePartDto & { processId?: string; materialTypeId?: string }]
		>({
			query: ([partId, partDto]) => {
				// remove form fields not present on entity
				delete partDto.processId;
				delete partDto.materialTypeId;
				return {
					url: `/parts/${partId}`,
					method: "PATCH",
					body: partDto,
				};
			},
			async onQueryStarted([partId, partDto], { dispatch, queryFulfilled }) {
				try {
					const { data: updatedPart } = await queryFulfilled;
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderId",
							updatedPart.orderId,
							(draft) => {
								return draft
									.filter(({ id }) => id !== updatedPart.id)
									.concat(updatedPart)
									.sort((a, b) =>
										dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? -1 : 1
									);
							}
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		deletePart: builder.mutation<PartEntity, string>({
			query: (partId) => ({
				url: `/parts/${partId}`,
				method: "DELETE",
			}),
			async onQueryStarted(partId, { dispatch, queryFulfilled }) {
				try {
					const { data: deletedDart } = await queryFulfilled;
					dispatch(
						partApi.util.updateQueryData(
							"getPartsByOrderId",
							deletedDart.orderId,
							(draft) => draft.filter((part) => part.id !== deletedDart.id)
						)
					);
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
		// @todo: does this belong here?
		presignS3Object: builder.query<{ presignedUrl: string }, PresignRequestDto>(
			{
				query: (body) => ({
					url: `/presign`,
					method: "POST",
					body,
				}),
			}
		),
		updatePosition: builder.mutation<
			PartEntity,
			{ partId: string; position: number }
		>({
			query: ({ partId, position }) => ({
				url: `/parts/${partId}/position`,
				method: "PATCH",
				body: { position },
			}),
			async onQueryStarted({ partId, position }, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					if (data) {
						dispatch(
							partApi.util.updateQueryData(
								"getPartsByOrderId",
								partId,
								(draft) => {
									const part = draft.find((p) => p.id === partId);
									if (part) {
										part.position = position;
									}
									return draft;
								}
							)
						);
					}
				} catch (e) {
					console.error("[API slice error]", e);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const {
	useGetPartsByOrderIdQuery,
	usePresignS3ObjectQuery,
	useUpdatePartMutation,
	useDeletePartMutation,
	useUpdatePositionMutation,
} = partApi;
export const getPartApiPromises = partApi.util.getRunningQueriesThunk;

// export endpoints for use in SSR
export const { getPartsByOrderId, updatePart } = partApi.endpoints;
