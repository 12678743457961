import Script from "next/script";

declare global {
	interface Window {
		dataLayer?: Object[];
	}
}

const GoogleAnalytics = () => {
	const gaId = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID;

	if (!gaId) {
		console.warn(
			"Google Analytics ID is not defined. Analytics will not be loaded."
		);
		return null;
	}

	if (process.env.NODE_ENV === "development") {
		console.log(
			"Google Analytics is disabled in development. Analytics will not be loaded."
		);
		return null;
	}

	return (
		<>
			<Script
				id="_next-ga-init"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){window.dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', '${gaId}', {
							page_path: window.location.pathname,
							hostname: window.location.hostname,
						});
								`,
				}}
			/>
			<Script
				id="_next-ga"
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
			/>
		</>
	);
};

export default GoogleAnalytics;
