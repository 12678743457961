import Script from "next/script";

const HubspotAnalytics = () => {
	const hubspotId = process.env.NEXT_PUBLIC_HUBSPOT_ID;

	if (!hubspotId) {
		console.warn(
			"Hubspot Analytics ID is not defined. Hubspot script will not be loaded."
		);
		return null;
	}

	if (process.env.NODE_ENV === "development") {
		console.debug(
			"Hubspot is disabled in development. Hubspot script will not be loaded."
		);
		return null;
	}

	return (
		<Script
			id="hs-script-loader"
			strategy="afterInteractive"
			src={`//js-eu1.hs-scripts.com/${hubspotId}.js`}
			type="text/javascript"
		/>
	);
};

export default HubspotAnalytics;
