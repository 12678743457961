import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { rfqApi } from "../rfqApi";
import { getIsServer, prepareHeaders } from "../utils";

import type {
	OrderAddress,
	UpdateOrderAddress,
} from "@rototip/lib-order/entities/order-address";

const urlPrefix = getIsServer() ? process.env.SVC_ORDER_URL : "";

export const orderAddressApi = createApi({
	reducerPath: "api:order-address",
	baseQuery: fetchBaseQuery({
		baseUrl: `${urlPrefix}/api/order/order-address`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		updateOrderAddress: builder.mutation<
			OrderAddress,
			[string, UpdateOrderAddress]
		>({
			query: ([id, body]) => {
				const filteredBody = {
					...body,
					state: body.state !== "" && body.state !== " " ? body.state : null,
				};
				return {
					url: `/${id}`,
					method: "POST",
					body: filteredBody,
				};
			},
			async onQueryStarted(dto, { dispatch, queryFulfilled }) {
				try {
					void (await queryFulfilled);
					dispatch(rfqApi.util.invalidateTags(["rfq"]));
				} catch (error) {
					console.error("[API slice error]", error);
				}
			},
		}),
	}),
});

// Export hooks for usage in functional components
export const { useUpdateOrderAddressMutation } = orderAddressApi;

// export endpoints for use in SSR and with form manager
export const { updateOrderAddress } = orderAddressApi.endpoints;
